<template>
    <o-data-lookup :data-object="dsLookupDataObject" :disableRecent="true">
        <template #toolbarActions>
            <slot name="toolbarActions"></slot>
        </template>

        <template #target="scope">
            <slot v-if="$slots.target" name="target" v-bind="scope"></slot>
            <span v-else-if="$slots.slotname" :ref="scope.target" style="cursor: pointer;">
                <slot name="slotname"></slot>
            </span>
        </template>      
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="200"></o-column> 
        <o-column field="Description" width="350"></o-column> 
    </o-data-lookup>
</template>

<script setup>
    import { computed, ref, watch, watchEffect } from 'vue';
    import { getOrCreateDataObject } from 'o365.vue.ts'

    const props = defineProps({
        is: String,   
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'atbv_Assets_ConditionLevels',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "string" },
              {name: "Name", type: "string", sortOrder: 1, sortDirection: "asc" },
              {name: "Description", type: "string" }]
    });


</script>